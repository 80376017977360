import React, { useState } from "react";
import image from "./study.png";
import config from "../../utils/config";
import apple from "./app-store-badge.webp";
import android from "./play-store-badge.webp";
import { Link } from "react-router-dom";
import { Download, Smartphone } from "lucide-react";

function Application() {
  const url = `${config.clientAdmin}`;
  const [isHovered, setIsHovered] = useState(false);
  const advantages = [
    // {
    //   title: "Recherche rapide",
    //   description: "Trouvez un professeur qualifié en quelques secondes",
    //   icon: "🔍",
    // },
    {
      title: "Profils détaillés",
      description:
        "Consultez les compétences et l'expérience de chaque enseignant",
      icon: "👨‍🏫",
    },
    {
      title: "Prise de rendez-vous",
      description: "Planifiez les cours directement depuis l'application",
      icon: "📅",
    },
    {
      title: "Paiement sécurisé",
      description: "Effectuez les transactions en toute sécurité",
      icon: "💳",
    },
    {
      title: "Suivi des progrès",
      description:
        "Suivez l'évolution de votre enfant avec des rapports détaillés",
      icon: "📊",
    },
    {
      title: "Communication facile",
      description: "Échangez avec les enseignants via la messagerie intégrée",
      icon: "💬",
    },
  ];

  return (
    <div className="home-contact-area signup-area">
      <center>
        <main
          style={{ maxWidth: "1200px", margin: "25px auto", padding: "2rem" }}
        >
          {/* Hero Section */}
          <section
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "4rem",
              flexWrap: "wrap",
            }}
          >
            <div style={{ flex: 1, minWidth: "300px" }}>
              <h2 style={{ fontSize: "2.5rem", marginBottom: "1rem" }}>
                Home Teacher Mobile !
              </h2>
              <p
                style={{
                  fontSize: "1.1rem",
                  marginBottom: "2rem",
                  lineHeight: "1.6",
                }}
              >
                Suivez facilement les progrès de vos enfants avec l'application
                Home Teacher. Téléchargez-la maintenant pour accéder à leur
                suivi éducatif, recevoir des mises à jour en temps réel et
                rester connecté avec leurs enseignants. Ne manquez pas
                l'opportunité de participer activement à leur réussite !
              </p>
              <div
                style={{
                  display: "flex",
                  // backgroundColor: "#3B4BF9",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <Link
                  to={
                    "https://expo.dev/artifacts/eas/btK4tHjKqLNZ1MiroaNjLX.apk"
                  }
                  style={{
                    width: "100%",
                    maxWidth: "20rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem", // Espacement plus large entre l'icône et le texte
                    backgroundColor: isHovered ? "#2a3bbf" : "#3B4BF9", // Couleur du bouton avec survol
                    color: "white",
                    padding: "1.2rem 1rem", // Ajustement du padding pour plus d'espace
                    borderRadius: "0.5rem", // Coins légèrement plus arrondis
                    border: "none",
                    cursor: "pointer",
                    transition: "all 0.3s ease-in-out", // Transition fluide pour tous les effets
                    textDecoration: "none",
                    boxShadow: isHovered
                      ? "0 4px 12px rgba(0, 0, 0, 0.2)"
                      : "none", // Ombre au survol
                    transform: isHovered ? "scale(1.05)" : "scale(1)", // Effet de zoom au survol
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <Smartphone size={24} style={{ color: "white" }} />
                  <Download size={24} style={{ color: "white" }} />
                  <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                    Télécharger l'Apk
                  </span>
                </Link>
              </div>
            </div>
            {/* <div style={{ flex: 1, textAlign: "center" }}> */}
            <div className="col-lg-6 " style={{ marginTop: "2rem" }}>
              <img src={image} alt="#" />
              {/* </div> */}
            </div>
          </section>

          {/* App Features */}
          <section>
            <h2
              style={{
                fontSize: "2rem",
                marginBottom: "2rem",
                textAlign: "center",
              }}
            >
              Avantages de l'application mobile
            </h2>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                gap: "2rem",
              }}
            >
              {advantages.map((feature) => (
                <div
                  key={feature.title}
                  style={{
                    // backgroundColor: "#f8f9fa",
                    borderRadius: "12px",
                    padding: "1.5rem",
                    textAlign: "center",
                    // boxShadow: "0 2px 4px rgba(0,0,0,0.1)"r,
                  }}
                >
                  <span
                    style={{
                      fontSize: "3rem",
                      marginBottom: "1rem",
                      display: "block",
                    }}
                  >
                    {feature.icon}
                  </span>
                  <h3
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {feature.title}
                  </h3>
                  <p style={{ fontSize: "0.9rem" }}>{feature.description}</p>
                </div>
              ))}
            </div>
          </section>
        </main>
      </center>
    </div>
  );
}

export default Application;
