import Footer from "./components/Footer";
import Header from "./components/Header";
import Landing from "./components/Landing";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Fragment, useEffect } from "react";
import Login from "./components/Login";
import Register from "./components/Register";
import ErrorPage from "./components/ErrorPage";
import RegisterProfesseur from "./components/Register/RegisterProfesseur";
import RegisterTuteur from "./components/Register/RegisterTuteur";
import RegisterEleve from "./components/Register/RegisterEleve";
import Contact from "./components/Contact";
import QuerySuccess from "./components/QuerySuccess";
import Offer from "./components/Offer";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/dark.css";
import ForgotPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ForgetPassword/ResetPassword";
import Application from "./components/app";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedData = urlParams.get("logout");
    if (encryptedData === "yes") {
      localStorage.removeItem("user");
      navigate("/");
    }
  }, []);

  return (
    <Fragment>
      <Header />
      <Outlet />
      <Footer />
    </Fragment>
  );
};

function ScrollToTopOnMount() {
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith("/navbar")) {
      // Exclure le défilement vers le haut sur les routes commençant par "/navbar"
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTopOnMount />
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="" element={<Landing />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="login" element={<Login />} />
            <Route path="offres" element={<Offer />} />
            <Route path="registerTuteur" element={<RegisterTuteur />} />
            <Route path="registerEleve" element={<RegisterEleve />} />
            <Route path="register" element={<Register />} />
            <Route path="app" element={<Application />} />
            <Route path="querySuccess" element={<QuerySuccess />} />
            <Route path="registerProfesseur" element={<RegisterProfesseur />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="reset_password" element={<ResetPassword />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
