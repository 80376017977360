import React from "react";
import { Link } from "react-router-dom";
import flyer1 from "../../assets/images/flyer2.jpg";
import flyer2 from "../../assets/images/flyer3.jpg";
import prof from "../../assets/images/prof.jpg";
import config from "../../utils/config";

function Offer() {
  const url = `${config.clientAdmin}`;

  return (
    <div className="home-contact-area signup-area">
      <div className="container">
        <div className="row align-items-center">
          <div
            className="signup-form"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h2>Offres</h2>
          </div>
        </div>
      </div>
      <center>
        <br />
        <h3>Inscrivez vous en tant que professeur</h3>
        <div style={{ maxWidth: 600, height: 600 }}>
          <img
            src={prof}
            alt="prof"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <Link to="/registerProfesseur" className="nav-link">
          Cliquez sur ce lien
        </Link>
        <br />
        <h4>Inscrivez votre enfant pour bénéficier de nos services</h4>
        <div style={{ maxWidth: 600, height: 600 }}>
          <img
            src={flyer1}
            alt="flyer1"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <Link to="/registerEleve" className="nav-link">
          Cliquez ici pour rechercher un encadreur
        </Link>
        <div style={{ maxWidth: 600, height: 750 }}>
          <img
            src={flyer2}
            alt="flyer2"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <br />
        <Link to="/registerEleve" className="nav-link">
          Cliquez ici pour rechercher un encadreur
        </Link>
        <br />
        <h3>
          <b>Nos services : </b>
        </h3>
        <br />
        <h5>1 - 💡 Cours gratuit en Informatique : </h5>
        <p>
          Préparez-les à un monde numérique en constante évolution. Notre
          initiation à l'informatique, proposée au moins une fois par mois,
          permet aux élèves d'acquérir des compétences de base essentielles pour
          réussir dans l'ère moderne.
        </p>
        <br />
        <h5>2 - 📊 Suivi du travail effectué par le professeur : </h5>
        <p>
          Nous vous permettons de suivre l'évolution du travail des professeurs
          avec vos enfants grâce à cette plateforme, car la confiance ne
          remplace pas le contrôle. Pour cela, il vous suffit de vous rendre sur
          votre tableau de bord après vous être connecté.
          <br />
        </p>
        <br />
        <h5>3 - 📋 Système de Notation et commentaire: </h5>
        <p>
          Votre avis est essentiel. Notre système de notation et de commentaires
          vous permet de vous exprimer librement (remarques, suggestions,
          plaintes, etc.) concernant un professeur en particulier. Pour cela, il
          vous suffit de vous rendre sur votre tableau de bord après vous être
          connecté.
          <br />
        </p>
        <br />
        <h5>4 - 📚 Support et Ressources Pédagogiques: </h5>
        <p>
          Nous offrons un accès à des ressources complémentaires (lorsqu'elles
          sont disponibles) pour approfondir les connaissances et élargir les
          horizons.
          <br />
        </p>
        <br />
        <h5>5 - 📈 Récompenses des éfforts des élèves : </h5>
        <p>
          Chaque effort mérite d'être célébré. C'est l'un de nos objectifs :
          nous nous engageons à récompenser les progrès des élèves qui
          travaillent avec nous par des gestes significatifs, afin de donner à
          vos enfants la confiance nécessaire pour briller.
        </p>
      </center>
    </div>
  );
}

export default Offer;
