import React from "react";
import choose from "./Mask.jpg";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export default function ChooseHomeTeacher() {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  return (
    <section className="choose-area">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 ">
            <div>
              <img
                src={choose}
                style={{ width: "100%", height: "100%" }}
                alt="choose"
              />
            </div>
          </div>
          <div className="col-lg-6 home-choose mt-lg-4 mt-md-4 mt-sm-n4">
            <div className="home-choose-content">
              <div>
                <h2>Vous souhaitez rejoindre notre équipe de professeurs ?</h2>

                <p>
                  Vous avez les compétences pour encadrer à domicile, mais vous
                  avez du mal à trouver des clients ou souhaitez en avoir
                  davantage ? Rejoignez notre réseau de professeurs particuliers
                  et bénéficiez d’une mise en relation avec des élèves en quête
                  d’accompagnement personnalisé. Nous vous offrons une
                  plateforme fiable pour développer votre activité, gérer votre
                  emploi du temps en toute flexibilité et transmettre votre
                  savoir dans les meilleures conditions. N’attendez plus,
                  saisissez cette opportunité dès aujourd’hui !
                </p>
              </div>

              <div className="row">
                <h6 className="text-center mb-4 text-decoration-underline">
                  Les avantages d’être professeur chez Home Teacher
                </h6>
                <p>
                  Veuillez cliquer sur chaque point pour obtenir plus
                  d’informations et découvrir tous les avantages d’être
                  professeur chez Home Teacher.
                </p>
                <div className="col-12">
                  <ul className="choose-list-left">
                    <Grid item>
                      <ClickAwayListener onClickAway={() => setOpen1(false)}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={open1}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Les professeurs enregistrés bénéficient d’un accès à une plateforme en ligne dédiée où ils peuvent créer leur profil, détailler leurs compétences, leur expérience et leurs disponibilités. Cette plateforme facilite la visibilité de leurs services et permet une meilleure promotion auprès des parents et des familles en quête d'un encadrement personnalisé."
                          >
                            <li onClick={() => setOpen1(!open1)}>
                              <i className="flaticon-check-mark"></i>
                              Accédez à une plateforme en ligne dédiée
                            </li>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </Grid>

                    <Grid item>
                      <ClickAwayListener onClickAway={() => setOpen2(false)}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={open2}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Home Teacher met en relation les professeurs avec des familles ayant des enfants à encadrer. Grâce à notre algorithme de mise en relation, les enseignants sont connectés avec des familles correspondant à leurs compétences et à leurs disponibilités, ce qui leur permet de trouver des clients rapidement et de manière efficace."
                          >
                            <li onClick={() => setOpen2(!open2)}>
                              <i className="flaticon-check-mark"></i>
                              Mise en relation directe avec des clients
                              potentiels.
                            </li>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </Grid>

                    <Grid item>
                      <ClickAwayListener onClickAway={() => setOpen3(false)}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={open3}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="En tant que professeur chez Home Teacher, vous pouvez définir vos propres horaires et disponibilités, offrant ainsi une flexibilité maximale pour concilier votre activité d'encadrement avec vos autres engagements professionnels ou personnels."
                          >
                            <li onClick={() => setOpen3(!open3)}>
                              <i className="flaticon-check-mark"></i>
                              Flexibilité et gestion optimale du temps
                            </li>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </Grid>

                    <Grid item>
                      <ClickAwayListener onClickAway={() => setOpen4(false)}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={open4}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Home Teacher offre un soutien administratif complet aux enseignants en prenant en charge les aspects logistiques tels que la facturation, les paiements et la communication avec les familles. Cela permet aux enseignants de se concentrer pleinement sur l'enseignement, tout en allégeant leur charge administrative liée à la recherche de clients et à la gestion des paiements."
                          >
                            <li onClick={() => setOpen4(!open4)}>
                              <i className="flaticon-check-mark"></i>
                              Support administratif complet
                            </li>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </Grid>

                    <Grid item>
                      <ClickAwayListener onClickAway={() => setOpen5(false)}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={open5}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="En rejoignant Home Teacher, les enseignants ont l'opportunité d’avoir un impact direct sur l'éducation des enfants en leur offrant un encadrement personnalisé à domicile. Ils peuvent ainsi contribuer à améliorer les performances scolaires des élèves et les aider à atteindre leurs objectifs éducatifs."
                          >
                            <li onClick={() => setOpen5(!open5)}>
                              <i className="flaticon-check-mark"></i>
                              Opportunité d'avoir un impact direct sur
                              l'éducation
                            </li>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </Grid>
                  </ul>
                </div>
                <Link to="/registerProfesseur" className="box-btn w-50">
                  Rejoignez notre équipe dès aujourd'hui !
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
