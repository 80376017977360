import img1 from "../../assets/images/courses/img1.png";
import img2 from "../../assets/images/courses/img2.png";

export default function Description() {
  return (
    <section className="home-ragular-course pb-100">
      <div className="container">
        <div className="text-center">
          <h3>Pourquoi choisir un encadrement à domicile ?</h3>
          <p>
            Parce qu’il favorise la réussite de vos enfants en offrant un
            environnement propice à l’apprentissage, une attention
            individualisée et la possibilité de développer des compétences
            adaptées à leurs besoins spécifiques..
          </p>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-lg-4 col-xl-4 col-md-6 col-12">
            <div className="single-ragular-course" style={{ height: 600 }}>
              <div
                style={{
                  backgroundColor: "#1d42d9",
                  padding: 50,
                  borderEndStartRadius: "100%",
                  borderEndEndRadius: "100%",
                }}
              >
                <h2 style={{ color: "white" }}>
                  Un climat de confiance <br />
                </h2>
              </div>
              <div className="course-content text-justify">
                <p>
                  L’avantage d’avoir un professeur particulier est qu’il ne juge
                  pas l’élève sur sa performance, mais s’adapte à son niveau et
                  à son rythme d’apprentissage. Cela permet d’instaurer une
                  véritable relation d’échange et un climat de confiance entre
                  le professeur et l’apprenant, créant ainsi les conditions
                  idéales pour progresser.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-6 col-12">
            <div className="single-ragular-course" style={{ height: 600 }}>
              <div
                style={{
                  backgroundColor: "#1d42d9",
                  padding: 50,
                  borderEndStartRadius: "100%",
                  borderEndEndRadius: "100%",
                }}
              >
                <h2 style={{ color: "white" }}>
                  Une plus grande culture générale
                </h2>
              </div>
              <div className="course-content text-justify">
                <p>
                  Réviser une matière donnée permet d’approfondir sa culture
                  générale dans le domaine de prédilection du professeur. De
                  plus, les élèves les plus motivés auront l’opportunité de
                  solliciter nos enseignants pour aller au-delà des attentes, en
                  anticipant les cours du lycée ou en recherchant des conseils
                  afin d’obtenir une mention bien, voire très bien, au BEPC ou
                  au baccalauréat.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-6 col-12">
            <div className="single-ragular-course" style={{ height: 600 }}>
              <div
                style={{
                  backgroundColor: "#1d42d9",
                  padding: 30,
                  borderEndStartRadius: "100%",
                  borderEndEndRadius: "100%",
                }}
              >
                <h2 style={{ color: "white" }}>
                  Une amélioration des résultats scolaires
                </h2>
              </div>
              <div className="course-content text-justify">
                <p>
                  Suivre des cours particuliers à domicile permet de combler des
                  lacunes dans certaines disciplines, ce qui entraîne une
                  amélioration des résultats et, par conséquent, une élévation
                  du niveau scolaire. C’est précisément l’objectif d’un
                  programme de cours intensifs ou de cours hebdomadaires.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
